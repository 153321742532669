import LoginScreen from 'components/screens/login/login-screen'
import { useAuthContext } from 'hooks/auth-context'
import { useRouter } from 'next/router'
import React from 'react'

const LoginRoute: React.FC = () => {
  const { user } = useAuthContext()
  const router = useRouter()
  if (user) router.push('/meus-cursos')
  return <LoginScreen />
}

export default LoginRoute
